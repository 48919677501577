<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg2 pr-6><v-select
              label="Nhân viên"
              :items="staff_options"
              v-model="staffName"
              item-text="Username"
              item-value="Username"
            ></v-select>
        </v-flex>
        <v-flex lg2 pr-6>
            <v-select
              label="Hình thức"
              :items="method_options"
              v-model="method"
              item-text="name"
              item-value="value"
            ></v-select>
        </v-flex>
        <v-flex lg3 pr-6 pl-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedStartDateFormatted"
                    label="Từ ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg3 pr-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedEndDateFormatted"
                    label="Đến ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg2 pr-6>
            <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
        </v-flex>
        <v-flex lg12>
             <template v-for="(item, i) in items">
                <div v-bind:key="i">
                <h4>Tháng: {{item.month.replace(/(\d{4})(\d{2})/, "$2-$1")}}</h4>
                <v-card class="mb-8">
                    <v-data-table fixed-header height="750px"   hide-default-footer :headers="headers" :items="item.rows" :server-items-length="item.rows.length" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.ChargeDate | display_date('DD-MM-YYYY')}}</td>
                                <td>{{ item.StaffName }}</td>
                                <td>{{ item.Method | display_value(recharge_methods) }}</td>
                                <td class="text-end">{{ item.Amount | currency}}</td>
                            </tr>
                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td class="font-weight-bold" colspan="3">TỔNG</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].Amount | currency}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
                </div>
            </template>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { request_list } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";
import moment from "moment"
export default {
    data() {
        return {
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "thong-ke-nap-tien",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
            recharge_methods: configs.RECHARGE_METHODS,
            staffName: '',
            method: 0
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.report.recharge.data,
            loading: state => state.report.loading,
            p_staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return ((o.RoleType & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment) || ((o.RoleType & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin);
            }),
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        roleAdmin() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        dic_summary() {
            let dic = {};
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                dic[item.month] = {
                    'Amount': _.sumBy(item.rows, 'Amount'),
                }
            }
            return dic;
        },
        headers() {
            return [
                {
                    text: "Ngày nạp",
                    value: "ChargeDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Người nạp",
                    value: "StaffName",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Hình thức",
                    value: "Method",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Enum"],
                    values: configs.RECHARGE_METHODS
                },
                {
                    text: "Tổng tiền",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                }
            ];
        },
        staff_options() {
            return [
                {'Username': ''},
                ...this.p_staffs
            ]
        },
        method_options() {
            return [
                {'name': '', value: 0},
                ...this.recharge_methods
            ]
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                pagination: {
                        sortDesc: this.pagination.sortDesc,
                        sortBy: this.pagination.sortBy
                    }
            };
            if(this.staffName != '') {
                param.staffName = this.staffName;
            }
            if(this.method != 0) {
                param.method = this.method;
            }
            this.$store.dispatch("report/getRecharge", param);
        }
    },
    mounted() {
        this.$store.dispatch("commons/getAllUsers");
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>